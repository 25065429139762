@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

.button {
  display: inline-flex; backdrop-filter: blur(2px);
  color: black;
  font-weight: 700;
  padding: 10px 45px;
  cursor: pointer;
  backdrop-filter: blur(15px);
  background: linear-gradient(270deg, #ffffff 2.56%, #FCEB79 92.24%);
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover{
  background: linear-gradient(90deg, #FBC62E 2.56%, #FCEB79 96.94%);
}

.primary-header {
  z-index: 5;
  padding-top: 1rem;
  display: grid;
  place-items: center;
  font-family: 'Montserrat';
  position: sticky;
  top: 0;
}

.nav-wrapper {
  padding: 1em 4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background-color: black;
  outline: 0.5px solid rgba(223, 193, 20, 0.25);
}

.mobile-nav-toggle {
  display: none;
}

.nav-list {
  font-size: 16px;
}

.nav-list {
  display: flex;
  gap: clamp(1rem, 5vw, 5rem);
  font-weight: var(--fw-semi-bold);
}

.nav-list{
  color: white;
  cursor: pointer;
}

.nav-list li{
  font-weight: 700;
}

.logo{
  display: flex;
  align-items: center;
  color: white;
}
.logo p{
font-family: "Montserrat";
}
.button {
  display: inline-flex; 
}

.display-sm-hidden {
  display: none;
}
.icon-close,
.icon-hamburger{
  color: white;
  z-index: 10;
}
.toggleDisplay{
display: none;
}
@media(min-width: 50em){
  .display-md-inline-flex{
      display: inline-flex;
  }
}
@media (max-width: 50em) {
  .primary-header {
    padding-top: 0;
    top: 0;
  }
  .mobile-width{
    width: 100% !important;
  }
  .nav-wrapper{
    border: none;
    border-radius: 0;
    padding: 0.5em 1em;
  }
  .primary-navigation {
    display: none;
    position: fixed;
    padding: 5em 2em;
    inset: 0rem 1em auto;
    max-width: 15rem;
    margin-left: auto;
    background: black;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 0.75em rgb(0, 0, 0, 0.05);
    z-index: 6;
  }

  .primary-header[data-overlay]::before {
    content: "";
    position: fixed;
    inset: 0;
    z-index: 5;
    /* height: 100vh; */
    background-image: linear-gradient(rgb(0 0 0 / 0.3), rgb(0 0 0 / 0.8));
  }

  .nav-list {
    display: grid;
    gap: var(--size-600);
    font-weight: var(--fw-bold);
  }

  .primary-navigation[data-visible] {
    display: block;
  }
  .toggleDisplay[data-visible]{
    display: block;
  }

  .mobile-nav-toggle {
    z-index: 10;
    display: block;
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0.5em;
  }

  .logo img{
      transform: scale(0.8);
  }

}

